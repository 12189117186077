.footer {
  &__top {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: #09394e;
    padding: 100px var(--body-padding);
    color: #fff;

    & h2 {
      font-size: 50px;
      margin-bottom: 20px;
      line-height: 74px;

      @media screen and (max-width: 600px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    &__disc {
      font-size: 18px;
      max-width: 670px;
      // margin-bottom: 50px;
      margin-bottom: 80px;
      line-height: 34px;
      font-weight: 300;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-bottom: 120px;
      flex-wrap: wrap;

      & button {
        & span {
          font-size: 10px;
          margin-bottom: 10px;
        }
      }
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;

      & a {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: #09394e;
    padding: 100px var(--body-padding);
    color: #fff;
    text-align: center;

    & h2 {
      font-size: 46px;
      font-weight: 400;
      margin-bottom: 50px;
    }

    &__form {
      width: 100%;
      max-width: 776px;
      &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-bottom: 20px;
        @media screen and (max-width: 700px) {
          grid-template-columns: 1fr;
        }

        & input {
          height: 60px;
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: 4px;
          padding: 0 20px;
          transition: 0.3s;
          color: #fff;
          font-size: 15px;

          &::placeholder {
            color: #ffffffc1;
            font-size: 15px;
          }

          &:focus {
            border: 1px solid $primary-color;
            outline: none;
          }
        }
      }

      & textarea {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        padding: 20px;
        transition: 0.3s;
        color: #fff;
        font-size: 15px;
        width: 100%;
        margin-bottom: 30px;

        &::placeholder {
          color: #ffffffc1;
          font-size: 15px;
        }

        &:focus {
          border: 1px solid $primary-color;
          outline: none;
        }
      }
    }
  }

  // & a {
  //   transition: 0.3s;

  //   &:hover {
  //     color: $primary-color;
  //   }
  // }

  &__links {
    background-color: #083346;
    padding: 80px var(--body-padding);
    padding-bottom: 40px;
    color: #fff;
  }

  &__main {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    max-width: 758px;
    margin: 0 auto;

    gap: 30px;
    // padding: 80px 0;
    padding-bottom: 80px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &__logo {
      & img {
        width: 100%;
        max-width: 230px;
      }

      & p {
        display: flex;
        align-items: center;
        gap: 20px;

        & img {
          width: 25px;
        }
      }
    }

    &__item {
      & h4 {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 400;
      }

      &__info {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 300;
        font-size: 16px;

        & a {
          color: #fff;
        }
      }
    }
  }

  &__bottom {
    border-top: 1px solid #ffffff12;
    max-width: 1108px;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    // font-size: 14px;
    gap: 20px;
    flex-wrap: wrap;

    &__links {
      display: flex;
      gap: 30px;

      & a {
        color: #fff;
      }
    }
  }
}
