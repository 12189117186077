.terms {
  padding: 50px var(--body-padding);
  line-height: 1.5;

  &__main {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 300px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      background-color: #fafbff;
    }

    &__right {
      & p {
        margin-bottom: 20px;
        color: #8b8b8b;
        font-size: 20px;
      }

      & h2 {
        margin-bottom: 20px;
        font-size: 40px;
        font-weight: 500;
      }
    }
  }
}
