.home {
  &__trusted {
    padding: 30px var(--body-padding);
    padding-bottom: 50px;

    & h3 {
      font-size: 20px;
      margin-bottom: 30px;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
    }
  }

  &__safe {
    padding: 50px var(--body-padding);
    & h3 {
      font-size: 20px;
      margin-bottom: 10px;

      & span {
        color: $primary-color;
      }
    }

    & h4 {
      font-size: 36px;
      max-width: 672px;
      margin-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 25px;
      }
    }

    &__btn {
      align-items: center;
      gap: 30px;
      background-color: #e9f7f6;
      padding: 20px;
      display: inline-flex;
      border-radius: 25px;
      margin-bottom: 50px;
      flex-wrap: wrap;

      &__item {
        font-size: 16px;
        padding: 15px 30px;

        &.active {
          background-color: $primary-color;
          color: #fff;
          border-radius: 15px;
        }
      }
    }

    &__main {
      display: grid;
      grid-template-columns: 3fr 4fr;
      gap: 30px;
      background-color: #d5faf8;
      padding: 0 7%;
      padding-top: 90px;
      border-radius: 20px;
      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      &__left {
        // align-content: center;
        margin-bottom: 30px;

        & h4 {
          font-size: 48px;
          max-width: 520px;
          margin-bottom: 30px;
          text-transform: capitalize;
        }

        &__list {
          & li {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
          }
        }

        &__btn {
          display: flex;
          gap: 20px;
          margin-top: 40px;
          flex-wrap: wrap;
        }
      }

      &__right {
        align-content: end;

        & img {
          width: 100%;
          position: relative;
          top: 4px;
        }
      }
    }
  }

  &__offer {
    padding: 100px var(--body-padding);
    background-image: url(../../../image/svg/offerBg.svg);
    background-color: #09394e;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;

    & h4 {
      font-size: 20px;
      color: $primary-color;
      margin-bottom: 10px;

      & span {
        color: #fff;
      }
    }

    & h3 {
      font-size: 36px;
      margin-bottom: 40px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      max-width: 1000px;

      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
      }

      &__item {
        background-color: #326984;
        padding: 8%;
        border-radius: 20px;

        & img {
          margin-bottom: 30px;
        }

        & h5 {
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 20px;
        }

        & p {
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
  }

  &__testimonial {
    background-color: #e9f7f6;
    padding: 100px var(--body-padding);
    padding-right: 0;

    & h3 {
      font-size: 32px;
      color: #02344a;
      margin-bottom: 50px;
    }
    &__list {
      &__item {
        // height: 100%;
        padding: 30px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        border-radius: 20px;
        text-align: center;
        min-height: 285px;

        & div {
          width: 56px;
          height: 2px;
          background-color: #23b1a8;
        }

        & p {
          font-size: 20px;
          color: #02344a;
          line-height: 35px;
        }

        & span {
          color: #23b1a8;
          font-size: 18px;
        }
      }
    }
  }
}
