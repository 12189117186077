.categories {
  padding: 50px var(--body-padding);

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 10px;

    & img {
      height: 13px;
    }
  }

  &__top {
    display: flex;
    gap: 30px;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
    // overflow: auto;

    // @media screen and (max-width: 800px) {
    // font-size: 17px;
    flex-wrap: wrap;
    gap: 20px;
    // }

    &__item {
      display: inline-block;
      padding: 8px 20px;
      //   background-color: $tetiary-color;
      //   color: white;
      //   border: 1px solid $primary-color;
      border-radius: 40px;
      cursor: pointer;
      font-size: 15px;
      text-transform: uppercase;
      transition: 0.3s;

      // @media screen and (max-width: 800px) {
      background-color: rgba(254, 108, 30, 0.083);
      // }
      &.active {
        color: $primary-color;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      & img {
        border-radius: 10px;
      }

      & p {
        font-weight: 600;
      }
    }
  }
}
