.hero {
  padding: 20px var(--body-padding);
  background: linear-gradient(
    180deg,
    rgba(68, 185, 176, 0.19) 0%,
    rgba(68, 185, 176, 0) 100%
  );

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
      margin-bottom: 50px;
    }

    & button {
      @media screen and (max-width: 600px) {
        width: 120px;
        font-size: 11px;
      }
    }
  }

  &__info {
    min-height: 700px;
    //   background-image: url(../../../images/hero.jpg);
    //   background-size: contain;
    //   background-position: center;
    //   background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    & h1 {
      font-size: 52px;
      font-weight: 700;
      margin-bottom: 10px;

      & span {
        color: $primary-color;
      }

      @media screen and (max-width: 600px) {
        font-size: 35px;
      }
    }
    & p {
      max-width: 470px;
      font-size: 20px;
      color: #003531;
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 30px;
      @media screen and (max-width: 500px) {
        flex-wrap: wrap;
      }

      & button {
        width: 150px;
        font-size: 12px;
      }
      //   @media screen and (max-width: 600px) {
      //     flex-wrap: wrap;
      //   }
    }
  }

  &__img {
    & img {
      width: 100%;
    }
  }
}
