$primary-color: #00847b;
$secondary-color: #893da8;
$tetiary-color: #fdefe3;

$text-color: #0a0e27;
$textAlt-color: #000;

$success-color: #22b548;
$error-color: #a92424;

// $box-shadow: -4px 7px 8px 0 rgba(15, 32, 60, 0.1);
